import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import { Layout } from '../components';

const AboutPage = ({ data }) => {
    const {
        body, heading, subHeading, metaTitle, metaDescription,
    } = _.head(data.graphCMS.aboutPages);

    return (
        <Layout metaTitle={metaTitle} metaDescription={metaDescription}>
            <h1>{heading}</h1>
            <p>{subHeading}</p>
            <ReactMarkdown source={body} escapeHtml={false} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query aboutPageQuery {
        graphCMS {
            aboutPages(last: 1) {
                id
                body
                heading
                metaTitle
                metaDescription
                subHeading
            }
        }
    }
`;

AboutPage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            aboutPages: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string,
                body: PropTypes.string,
                heading: PropTypes.string,
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                subHeading: PropTypes.string,
            })),
        }),
    }).isRequired,
};

export default AboutPage;
